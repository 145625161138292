import styled from 'styled-components'

export const FeaturedContainer = styled.div`
  width: 100%;
  max-width: 1440px;
`
export const Sectioner = styled.div`
  position: relative;
  margin-top: ${props =>
    props.medium ? ' 40px' : props.header ? '38px' : null};
  margin-bottom: ${props =>
    props.small
      ? '50px'
      : props.medium
      ? ' 20px'
      : props.header
      ? '20px'
      : null};
  @media (min-width: 480px) {
    margin-top: ${props =>
      props.medium ? ' 80px' : props.header ? '38px' : null};

    margin-bottom: ${props =>
      props.small
        ? '50px'
        : props.medium
        ? ' 20px'
        : props.header
        ? '30px'
        : null};
  }

  @media (min-width: 1024px) {
    margin-bottom: ${props =>
      props.small
        ? '80px'
        : props.medium
        ? ' 50px'
        : props.header
        ? '55px'
        : null};
  }

  a {
    padding: 15px;

    :hover {
      color: #a55331;
    }
  }

  h2 {
    padding: ${props =>
      props.medium
        ? ' 16px'
        : props.small
        ? '20px'
        : props.header
        ? '28px 10px'
        : null};
    text-transform: none;
    text-align: center;
    font-weight: ${props => (props.small ? '500' : null)};
    font-size: ${props =>
      props.medium
        ? '18px'
        : props.small
        ? '12px'
        : props.header
        ? '32px'
        : null};

    @media (min-width: 768px) {
      font-size: ${props =>
        props.medium
          ? '23px'
          : props.small
          ? '14px'
          : props.header
          ? '36px'
          : null};
      padding: ${props => (props.medium ? '23px' : null)};
    }

    @media (min-width: 1024px) {
      font-size: ${props =>
        props.medium
          ? '24px'
          : props.small
          ? '14px'
          : props.header
          ? '48px'
          : null};
      padding: ${props =>
        props.medium ? '39px' : props.small ? '39px' : null};
    }
  }

  :before,
  :after {
    width: 100%;
    top: 0;
    height: 1px;
    background: #212c1f;
    position: absolute;
    content: '';
  }

  ::before {
    background: #889390;
  }

  :after {
    top: auto;
    bottom: 0;
  }
`

export const ArticlesContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    grid-column-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-column-gap: 40px;
  }
  @media (min-width: 1280px) {
    grid-column-gap: 55px;
  }
`

export const RightWrapper = styled.div`
  display: grid;
  grid-column: span 2;
  grid-gap: 20px;

  > div {
    :nth-of-type(3) {
      ::after {
        display: none;
      }
    }
  }

  @media (min-width: 480px) {
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 768px) {
    grid-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 1fr;
  }

  @media (min-width: 1024px) {
    grid-gap: 30px;
  }

  @media (min-width: 1280px) {
    grid-gap: 40px;
  }
`

export const MainWrapper = styled.div`
  display: grid;
  grid-column: span 2;
  /* grid-row: span 3; */
`
