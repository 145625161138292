import React, { Component } from 'react'

import Slider from 'react-slick'
import '../../utils/slick.css'
import '../../utils/slick-theme.css'
import { AvenueImg } from '../AvenueImg'

import '../../utils/animate.css'

import {
  ButtonContainer,
  Button,
  PreviousSVG,
  NextSVG,
  TextContainer,
  ImageContainer,
  TextWrapper,
  Container,
  ImageWrapper,
} from './style'

export default class ImageTextCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.heading = props.data.heading
    this.carouselData = props.data.items
    this.slideChanged = this.slideChanged.bind(this)

    this.state = {
      current: this.carouselData[0],
      previousIndex: 0,
      currentIndex: 0,
      days: this.carouselData[0].days,
      title: this.carouselData[0].title,
      desc: this.carouselData[0].excerpt,
      desc: this.carouselData[0].excerpt,
      uri: this.carouselData[0].uri,
    }
  }

  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }

  slideChanged(oldIndex, newIndex) {
    this.setState(
      {
        current: this.carouselData[newIndex],
        currentIndex: newIndex,
        previousIndex: oldIndex,
      },
      () =>
        this.animateCSS(
          '.info-slide--var',
          `${
            oldIndex === 0 && newIndex === 7
              ? 'fadeOutLeft'
              : oldIndex < newIndex || (oldIndex === 7 && newIndex === 0)
              ? 'fadeOutRight'
              : 'fadeOutLeft'
          }`,
          () => this.updateInfo(oldIndex, newIndex)
        )
    )
  }

  animateCSS(element, animationName, callback) {
    const node = document.querySelector(element)
    node.classList.add('animated', animationName)

    function handleAnimationEnd() {
      node.classList.remove('animated', animationName)
      node.removeEventListener('animationend', handleAnimationEnd)

      if (typeof callback === 'function') {
        callback()
      }
    }

    node.addEventListener('animationend', handleAnimationEnd)
  }

  updateInfo(oldIndex, newIndex) {
    this.setState(
      {
        days: this.state.current.days,
        title: this.state.current.title,
        desc: this.state.current.excerpt,
        uri: this.state.current.uri,
      },
      () =>
        this.animateCSS(
          '.info-slide--var',
          `${
            oldIndex === 0 && newIndex === 7
              ? 'fadeInRight'
              : oldIndex < newIndex || (oldIndex === 7 && newIndex === 0)
              ? 'fadeInLeft'
              : 'fadeInRight'
          }`
        )
    )
  }

  render() {
    const settings = {
      fade: true,
      infinite: true,
      speed: 700,
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: this.slideChanged,
    }

    return (
      <Container>
        <ImageContainer>
          <Slider ref={c => (this.slider = c)} {...settings}>
            {this.carouselData.map((feature, index) => {
              return (
                <ImageWrapper key={index}>
                  <AvenueImg
                    style={{ position: 'absolute', width: '100%' }}
                    fluid={{
                      ...feature.image,
                    }}
                    alt=""
                  />
                </ImageWrapper>
              )
            })}
          </Slider>
        </ImageContainer>

        <TextContainer>
          <TextWrapper className="info-slide--var faster5">
            <h5>{this.heading ||  `Coming up next season`}</h5>
            <h2>{this.state.title}</h2>
            <p>{this.state.desc}</p>
            <a href={this.state.uri}>Read Story</a>
          </TextWrapper>

          <ButtonContainer>
            <Button previous onClick={this.previous}>
              <PreviousSVG />
            </Button>
            <Button next onClick={this.next}>
              <NextSVG />
            </Button>
          </ButtonContainer>
        </TextContainer>
      </Container>
    )
  }
}
