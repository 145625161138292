import styled from 'styled-components'

export const TeaserBlockContainer = styled.div`
  overflow: hidden;
  width: auto;
  margin: 10px 0px;

  @media (min-width: 768px) {
    margin: 28px 0px;
  }

  @media (min-width: 1280px) {
    margin: 40px 0px;
  }

  hr {
    display: ${props =>
    props.passes ? 'block':
      (props.other ||
      props.three ||
      props.two ||
      props.one ||
      props.accommodation)
        ? 'none'
        : 'block'};
    background: black;
    margin: 0;
  }
`

export const TeaserBlockGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${props =>
    props.other ? '15px' : props.accommodation ? '30px' : '10px'};
  grid-row-gap: ${props => (props.other ? '20px' : null)};

  grid-auto-flow: row;
  justify-content: center;
  margin-bottom: ${props => (props.passes ? '40px': props.itinerary ? '20px' : null)};

  @media (min-width: 480px) {
    grid-gap: ${props =>
      (props.other || props.three || props.accommodation) && '20px'};
    grid-template-columns: ${props =>
      (props.other || props.three || props.accommodation) && '1fr 1fr'};
  }

  @media (min-width: 600px) {
    grid-auto-flow: ${props => props.itinerary && 'column'};
    grid-auto-columns: ${props => props.itinerary && 'minmax(10px, 363px)'};
    grid-gap: ${props => props.itinerary && '20px'};
    grid-template-columns: ${props => props.itinerary && 'none'};
  }
  @media (min-width: 768px) {
    grid-template-columns: ${props =>
      props.other
        ? '1fr 1fr 1fr 1fr'
        : props.three
        ? '1fr 1fr 1fr'
        : props.two
        ? '1fr 1fr'
        : props.accommodation || props.one
        ? '1fr'
        : null};
    grid-gap: 30px;
    width: auto;
    margin-bottom: ${props => (props.passes ? '40px': props.itinerary ? '30px' : null)};
  }

  @media (min-width: 1280px) {
    grid-gap: 55px;
    margin-bottom: ${props => (props.passes ? '40px': props.itinerary ? '55px' : null)};
  }

  .last-teaser {
    padding-bottom: 60px;
  }
`
