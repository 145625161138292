import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { ResponsiveImage } from '../ResponsiveImage';

import '../../utils/slick.css';
import '../../utils/slick-theme.css';

import Slider from 'react-slick';

import { CarouselContainer, CarouselWrapper, CarouselPage, Caption } from '../ItineraryDetails/style.js';

class ItineraryCarousel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			caption: this.props.gallery[0].caption
		};

		this.updateCaption = this.updateCaption.bind(this);
	}

	updateCaption(oldIndex, newIndex) {
		const newCaption = this.props.gallery[newIndex].caption;
		// console.log(newIndex);
		// this.slider.slickGoTo(newIndex);

		setTimeout(() => {
			this.setState({ caption: newCaption });
		}, 10);
	}

	render() {
		const settings = {
			fade: true,
			pauseOnFocus: false,
			infinite: true,
			lazyLoad: true,
			slidesToShow: 1,
			dots: true,
			autoplay: true,
			autoplaySpeed: 6000,
			slidesToScroll: 1,
			beforeChange: this.updateCaption,

			customPaging: function(i) {
				return (
					<div>
					<CarouselPage>
						{i + 1}
					</CarouselPage>

					<div id="countdown">
							<svg>
								<circle r="18" cx="20" cy="20" />
							</svg>
						</div>
					</div>

				);
			}
		};

		return (
			<CarouselContainer custom>
				<CarouselWrapper custom>
					<Slider ref={(c) => (this.slider = c)} {...settings}>
						{this.props.gallery.map((slide, index) => {
							return (
								<img key={index} src={slide.src} srcSet={slide.srcSet} sizes="1100px" alt={slide.src} />
							);
						})}
					</Slider>
				</CarouselWrapper>

				<Caption hide={this.state.caption.length < 2}>{this.state.caption}</Caption>
			</CarouselContainer>
		);
	}
}

export default ItineraryCarousel;
