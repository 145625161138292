import styled from 'styled-components'
import Arrow from './img/next.svg'
import Arrow2 from './img/previous.svg'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1440px;
  margin: 50px 0;
  margin-bottom: 0;

  @media (min-width: 768px) {
    margin: 100px -30px 0;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1280px) {
    margin: 100px -120px 0;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  padding-top: 100%;

  .slick-slider {
    position: absolute;
    top: 0;
  }
`

export const ImageWrapper = styled.div`
  img {
    display: block;
    margin: 0;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  width: 100%;
  height: 100%;
`

export const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  bottom: -1px;
  right: 0;
  z-index: 3;
`

export const PreviousSVG = styled.div`
  background-image: url(${Arrow2});
  background-repeat: no-repeat;
  width: 10px;
  height: 16px;
`

export const NextSVG = styled.div`
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  width: 10px;
  height: 16px;
`

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  width: 48px;
  height: 48px;
  border: 1px solid rgb(251, 249, 246, 0.5);
  border-bottom: none;
  border-left: ${props => props.next && 'transparent'};
  display: grid;
  place-items: center;
  opacity: 0.5;

  :hover {
    opacity: 1;
    border: 1px solid rgb(251, 249, 246);
    border-right: ${props => props.next && 'transparent'};
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    width: 72px;
    height: 72px;
    font-weight: 500;
  }
`

export const TextContainer = styled.div`
  background: #327343;
  padding: 38px;
  position: relative;

  @media (min-width: 768px) {
    padding: 0 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1024px) {
    padding-left: 80px;
    padding-right: 60px;
  }

  @media (min-width: 1280px) {
    padding-left: 130px;
    padding-right: 115px;
  }
`

export const TextWrapper = styled.div`
  h5 {
    font-size: 12px;
    color: #ffffff;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0;
    line-height: 166.666%;
    margin-bottom: 4px;

    @media (min-width: 1024px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  h2 {
    font-size: 32px;
    color: #ffc5dc;
    letter-spacing: -0.29px;
    line-height: 100%;
    text-transform: none;

    @media (min-width: 1024px) {
      font-size: 48px;
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 16px;
    color: #fbf9f6;
    margin-bottom: 15px;

    @media (min-width: 1024px) {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }

  a {
    font-weight: 500;
    text-transform: uppercase;
    color: #ffc5dc;
    font-size: 12px;

    @media (min-width: 1024px) {
      font-size: 14px;
    }
  }
`
