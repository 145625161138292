import React, { Component } from 'react';
import {
	EnterCompetitionContainer,
	CompetitionImageWrapper,
	CompetitionTextWrapper,
	CompetitionInfoWrapper,
	Dates,
	CompetitionContent,
} from './style.js';
import { AvenueImg } from '../AvenueImg'
import { getImageRatio } from '../../utils/helpers'
import { ReactComponent as CalendarIcon } from '../../images/calendar.svg';

import { BrownButton } from '../BrownButton/index.js';

class EnterCompetition extends Component {
	render() {


		return (
			<EnterCompetitionContainer>
				<CompetitionImageWrapper>
					<AvenueImg fluid={{
						...this.props.image,
						sizes: "600px"
					}} alt={this.props.title} />

				</CompetitionImageWrapper>

				<CompetitionTextWrapper>
					<CompetitionInfoWrapper>
						<h3>{this.props.title}</h3>

						<Dates>
							<CalendarIcon />
							<p>{this.props.date}</p>
						</Dates>

						<CompetitionContent	dangerouslySetInnerHTML={{ __html: this.props.description }}/>

					</CompetitionInfoWrapper>

					<BrownButton text={this.props.ctaLabel} link={this.props.ctaLink} />

				</CompetitionTextWrapper>
			</EnterCompetitionContainer>
		);
	}
}

export default EnterCompetition;
