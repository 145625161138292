import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .slick-slide {
    padding: 0 10px;

    @media (min-width: 768px) {
      padding: 0;
      padding: 15px;
    }

    @media (min-width: 1280px) {
      padding: 27.5px;
    }

    .info {
      opacity: 0;
      transition: 700ms;
      transition-delay: 10ms;
      @media (min-width: 768px) {
        opacity: 1;
      }
    }

    &.slick-active {
      .info {
        opacity: 1;
      }
    }
  }
`

export const Header = styled.h3`
  text-transform: none;
  font-family: Freight Big;
  font-size: 20px;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 28px;
    margin-bottom: 33px;
  }
`

export const SelectorContainer = styled.div`
  margin-bottom: 28px;

  @media (min-width: 768px) {
    margin-bottom: 61px;
  }

  button {
    position: relative;
    outline: none;
    border: none;
    background: none;
    margin: 0;
    margin-right: 20px;
    position: relative;

    @media (min-width: 768px) {
      margin-right: 38px;
    }

    :hover {
      cursor: pointer;
      h4 {
        color: black;
      }
    }
    ::after {
      position: absolute;
      content: '';
      height: 100%;
      width: 1px;
      background: #b9bab4;
      top: 0;
      right: 0;
      right: -12px;
      @media (min-width: 768px) {
        right: -17px;
      }
    }
    :last-of-type {
      margin-right: 0;
      ::after {
        display: none;
      }
    }

    h4 {
      transition: 100ms;
      margin: 0;
      display: inline-block;
      text-transform: none;
      font-size: 20px;

      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 36px;
      }
    }
  }

  .stay-btn {
    pointer-events: ${props => props.active === 'stay' && 'none'};
    h4 {
      color: ${props => (props.active === 'stay' ? 'black' : '#B9BAB4')};
      cursor: ${props => !props.active === 'stay' && 'pointer'};
    }
  }

  .play-btn {
    pointer-events: ${props => props.active === 'play' && 'none'};
    h4 {
      color: ${props => (props.active === 'play' ? 'black' : '#B9BAB4')};
      cursor: ${props => !props.active === 'play' && 'pointer'};
    }
  }
  .eat-btn {
    pointer-events: ${props => props.active === 'eat' && 'none'};
    h4 {
      color: ${props => (props.active === 'eat' ? 'black' : '#B9BAB4')};
      cursor: ${props => !props.active === 'eat' && 'pointer'};
    }
  }
  .drink-btn {
    pointer-events: ${props => props.active === 'drink' && 'none'};
    h4 {
      color: ${props => (props.active === 'drink' ? 'black' : '#B9BAB4')};
      cursor: ${props => !props.active === 'drink' && 'pointer'};
    }
  }
`

export const SliderContainer = styled.div``
