import React from 'react'
import { Teaser } from '../Teaser'
import { TeaserBlockContainer, TeaserBlockGrid } from './TeaserBlockStyles.js'
import { SubTitle } from '../FestivalDetails/style.js'
import { MoreResults } from '../MoreResults'

const TeaserBlock = ({
  teasers,
  heading,
  three,
  two,
  one,
  accommodation,
  itinerary,
  other,
  cta_link,
  cta_label,
}) => {
  console.log('teasers:', teasers)

  return (
    <TeaserBlockContainer
      three={three}
      two={two}
      one={one}
      accommodation={accommodation}
      itinerary={itinerary}
      other={other}
    >
      {one ? null : <SubTitle>{heading}</SubTitle>}
      <TeaserBlockGrid
        three={three}
        two={two}
        one={one}
        accommodation={accommodation}
        itinerary={itinerary}
        other={other}
      >
        {teasers?.map((teaser, index) => {
          return (
            <Teaser
              removePadding
              date={teaser.dates}
              key={index}
              featuredTitle={heading}
              event={one || three}
              fullWidth={one}
              featured={one}
              storyMain={two}
              lineD={two}
              one={one}
              itinerary={itinerary}
              days={teaser.days}
              standard={three}
              festivalStay={accommodation}
              festivalHighlight={other}
              hideFavourites
              bookingLink={other}
              search={other}
              address={teaser.secondary_text}
              region={teaser.region}
              website={teaser.link}
              categories={teaser.categories}
              img={{
                url: teaser.img_src,
                srcSet: teaser.img_srcSet,
                sizes: '500px',
              }}
              title={teaser.title}
              desc={teaser.description}
              lastTeaser={accommodation && index === teasers.length - 1}
              url={teaser.link}
            />
          )
        })}
      </TeaserBlockGrid>

      {accommodation && (
        <MoreResults cta_link={cta_link} cta_label={cta_label} accommodation />
      )}
    </TeaserBlockContainer>
  )
}

export default TeaserBlock
