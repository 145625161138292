import React from 'react'
import { TeaserUnearth } from '../TeaserUnearth'
import { Link } from 'gatsby'

import {
  FeaturedContainer,
  Sectioner,
  ArticlesContainer,
  RightWrapper,
  MainWrapper,
} from './style.js'

function ArticlesFeatured({ data }) {
  const firstArticles = data.items[0]

  return (
    <FeaturedContainer>
      <Sectioner medium>
        <h2>Articles</h2>
      </Sectioner>

      {data.items && (
        <ArticlesContainer>
          <MainWrapper>
            {firstArticles && (
              <TeaserUnearth
                articleMain
                title={firstArticles?.title}
                excerpt={firstArticles?.excerpt}
                category={firstArticles?.categories}
                path={firstArticles?.uri}
                fluid={{
                  ...firstArticles?.image,
                  sizes: '784px',
                }}
              />
            )}
          </MainWrapper>

          <RightWrapper>
            {data.items.map(
              (teaser, index) =>
                index !== 0 && (
                  <TeaserUnearth
                    key={index}
                    article
                    standard
                    path={teaser.uri}
                    title={teaser.title}
                    excerpt={teaser.excerpt}
                    category={teaser.categories}
                    fluid={{
                      ...teaser.image,
                      sizes: '166px',
                    }}
                  />
                )
            )}
          </RightWrapper>
        </ArticlesContainer>
      )}
      {data.view_all_link && (
        <Sectioner small>
          <h2>
            <Link to={data.view_all_link}>VIEW ALL</Link>
          </h2>
        </Sectioner>
      )}
    </FeaturedContainer>
  )
}

export default ArticlesFeatured
