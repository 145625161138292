import React from 'react'
import HTMLReactParser from 'html-react-parser'
import TeaserBlock from './TeaserBlock'
import InnerHTML from 'dangerously-set-html-content'
import CustomCarousel from './CustomCarousel.js'
import SignUp from '../SignUp/LargeSignUp/'
import FestivalsWidget from '../FestivalDetails/FestivalsWidget.js'
import EnterCompetition from '../FestivalDetails/EnterCompetition'
import ItineraryNoSideBar from '../Map/ItineraryMap/index'
import HubspotForm from 'react-hubspot-form'

import { ImageTextCarousel } from '../ImageTextCarousel'
import { ArticlesFeatured } from '../ArticlesFeatured'
import { StayPlay } from '../StayPlay'

function getPhotos(children) {
  let photos = []

  children.forEach(img => {
    const theImg = img.children[0].children[0]
    const theCaption = img.children[0].children[1]

    if (theImg.attribs) {
      photos.push({
        src: theImg.attribs.src,
        srcSet: theImg.attribs.srcset,
        caption: theCaption ? theCaption.children[0].data : '',
      })
    }
  })

  return photos
}

function getTeaserList(children) {
  let teasers = []

  children.forEach(teaser => {
    if (!teaser.attribs) return

    const {
      title,
      src,
      srcset,
      description,
      secondary_text,
      link,
    } = teaser.attribs
    // console.log('teaser.attribs:', teaser.attribs)

    teasers.push({
      title,
      description,
      secondary_text,
      link,
      img_src: src,
      img_srcSet: srcset ? JSON.parse(srcset) : '',
    })
  })
  return teasers
}

function getMapData(children) {
  let markers = []

  children.forEach(teaser => {
    if (!teaser.attribs) return

    const {
      title,
      src,
      srcset,
      description,
      image,
      lat,
      lng,
      link,
    } = teaser.attribs

    markers.push({
      stopName: title,
      description,
      path: link,
      imageURL: image,
      map: { lat, lng },
    })
  })

  return [markers]
}

function getSignupData(attribs) {
  const { heading, sub_heading, description, tag } = attribs

  let data = {
    heading,
    sub_heading,
    description,
    tag,
  }

  return data
}

function getGroupedSectionData(attribs, children) {
  const { featurecontent, heading, introtext, srcset, src } = attribs
  let data = {
    featurecontent,
    heading,
    introtext,
    img_src: src,
    img_srcSet: srcset ? JSON.parse(srcset) : '',
    items: [],
  }

  children.forEach(teaser => {
    if (!teaser.attribs) return

    const {
      title,
      src,
      srcset,
      description,
      categories,
      link,
      postdates,
      region,
    } = teaser.attribs

    data.items.push({
      region: region && isJsonString(region) ? JSON.parse(region) : null,
      title,
      description,
      link,
      categories: categories ? JSON.parse(categories) : [],
      dates: postdates ? JSON.parse(postdates) : [],
      img_src: src,
      img_srcSet: srcset ? JSON.parse(srcset) : '',
    })
  })

  return data
}

function getFeaturedCustomData(attribs) {
  const {
    ctalabel,
    ctalink,
    date,
    description,
    src,
    title,
    srcset,
    image,
  } = attribs

  let data = {
    ctalabel,
    ctalink,
    date,
    description,
    title,
    image: JSON.parse(attribs.image),
  }

  return data
}

function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

function getFeaturedContent(children) {
  let teasers = []

  children.forEach(teaser => {
    if (!teaser.attribs) return
    console.log('teaser.attribs:', teaser.attribs)

    const {
      title,
      src,
      srcset,
      description,
      categories,
      days,
      link,
      postdates,
      region,
    } = teaser.attribs

    teasers.push({
      region: region && isJsonString(region) ? JSON.parse(region) : null,
      title,
      description,
      link,
      days: days,
      img_src: src,
      img_srcSet: srcset ? JSON.parse(srcset) : '',
      categories: categories ? JSON.parse(categories) : [],
      dates: postdates ? JSON.parse(postdates) : [],
    })
  })

  return teasers
}

export function parseBlockContent(markup, sidebar = false) {
  const Content = HTMLReactParser(markup, {
    replace: ({ attribs, children }) => {
      if (!attribs) return

      if (
        attribs.src?.endsWith('.js') ||
        attribs.type?.indexOf('javascript') > -1
      ) {
        return <InnerHTML html={`<script defer src=${attribs.src} />`} />
      }

      if (attribs.class && attribs.class.includes('block_teaser_list')) {
        return (
          <TeaserBlock
            accommodation
            teasers={getTeaserList(children)}
            heading={attribs.heading}
            cta_label={attribs.cta_label}
            cta_link={attribs.cta_link}
          />
        )
      }

      if (attribs.class && attribs.class.includes('blocks-gallery-grid')) {
        return <CustomCarousel gallery={getPhotos(children)} />
      }

      if (attribs.class && attribs.class.includes('block_operator_map')) {
        const data = getMapData(children)

        // nsbData = No Side Bar Data
        return <ItineraryNoSideBar noSideBar="noSideBar" nsbData={data} />
      }

      if (attribs.class && attribs.class.includes('featured_content--single')) {
        return (
          <TeaserBlock
            one
            teasers={getFeaturedContent(children)}
            heading={attribs.heading}
          />
        )
      }

      if (attribs.class && attribs.class.includes('email_signup')) {
        const data = getSignupData(attribs)

        return (
          <SignUp
            tag={data.tag || ''}
            heading={data.heading}
            sub_heading={data.sub_heading}
            description={data.description}
          />
        )
      }

      if (attribs.class && attribs.class.includes('grouped_section')) {
        const data = getGroupedSectionData(attribs, children)
        return (
          <FestivalsWidget
            content={data.featurecontent}
            heading={data.heading}
            img_src={data.img_src}
            img_srcSet={data.img_srcSet}
            introText={data.introtext}
            teasers={data.items}
          />
        )
      }

      if (attribs.class && attribs.class.includes('featured_custom')) {
        const data = getFeaturedCustomData(attribs)

        return (
          <EnterCompetition
            ctaLabel={data.ctalabel}
            ctaLink={data.ctalink}
            date={data.date}
            description={data.description}
            image={data.image}
            title={data.title}
          />
        )
      }

      if (attribs.class && attribs.class.includes('featured_carousel')) {
        const data = JSON.parse(attribs.data)

        if (data.items.length === 0) return false
        return <ImageTextCarousel data={data} />
      }

      if (attribs.class && attribs.class.includes('featured_articles')) {
        const data = JSON.parse(attribs.data)
        return <ArticlesFeatured data={data} />
      }

      if (attribs.class && attribs.class.includes('featured_stayplay')) {
        const dataStay = JSON.parse(attribs.datastay)
        const dataPlay = JSON.parse(attribs.dataplay)
        const dataEat = JSON.parse(attribs.dataeat)
        const dataDrink = JSON.parse(attribs.datadrink)

        // console.log(dataStay)
        // console.log(dataPlay)
        // console.log(dataEat)
        // console.log(dataDrink)

        return (
          <>
            {dataStay && dataPlay && (
              <StayPlay
                dataStay={dataStay}
                dataPlay={dataPlay}
                dataEat={dataEat}
                dataDrink={dataDrink}
              />
            )}
          </>
        )
      }

      if (attribs.class && attribs.class.includes('featured_content--double')) {
        return (
          <TeaserBlock
            two
            teasers={getFeaturedContent(children)}
            heading={attribs.heading}
          />
        )
      }

      if (attribs.class && attribs.class.includes('featured_content--triple')) {
        return (
          <TeaserBlock
            three
            teasers={getFeaturedContent(children)}
            heading={attribs.heading}
          />
        )
      }

      if (
        attribs.class &&
        attribs.class.includes('featured_content--quadruple')
      ) {
        return (
          <TeaserBlock
            other
            teasers={getFeaturedContent(children)}
            heading={attribs.heading}
          />
        )
      }

      if (attribs.class && attribs.class.includes('featured_content--tall')) {
        return (
          <TeaserBlock
            itinerary
            teasers={getFeaturedContent(children)}
            heading={attribs.heading}
          />
        )
      }
      if (attribs.class && attribs.class.includes('hubspot-form')) {
        return (
          <div style={{ maxWidth: '780px', margin: '0 auto' }}>
            <HubspotForm portalId={attribs.portalid} formId={attribs.formid} />
          </div>
        )
      }
    },
  })

  return Content
}
