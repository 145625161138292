import React, { Component } from 'react'
import { WidgetContainer, StoriesTeaserWrapper } from './style.js'
import { Teaser } from '../Teaser'
import { ContentStyles } from '../../utils/globalStyledComponents'
import { ResponsiveImage } from '../ResponsiveImage'

class FestivalsWidget extends Component {
  render() {
    console.log('this.props.teasers:', this.props.teasers)

    return (
      <WidgetContainer>
        <h3>{this.props.heading}</h3>
        <p>{this.props.introText}</p>
        <ContentStyles>
          <div className="image-text festivals">
            <div className="image-text__col image-text__col--img festivals">
              <ResponsiveImage
                src={this.props.img_src}
                sizes="650px"
                srcSet={this.props.img_srcSet}
                alt={this.props.heading}
              />
            </div>
            <div className="image-text__col image-text__col--text festivals">
              <div
                className="image-text__content festivals"
                dangerouslySetInnerHTML={{ __html: this.props.content }}
              />
            </div>
          </div>
        </ContentStyles>
        <StoriesTeaserWrapper>
          {this.props.teasers &&
            this.props.teasers.map((teaser, index) => (
              <Teaser
                key={index}
                story
                title={teaser.title}
                categories={teaser.categories}
                region={teaser.region}
                date={teaser.dates}
                img={{
                  url: teaser.img_src,
                  srcSet: teaser.img_srcSet,
                  sizes: '160px',
                }}
                url={teaser.link}
              />
            ))}
        </StoriesTeaserWrapper>
      </WidgetContainer>
    )
  }
}

export default FestivalsWidget
