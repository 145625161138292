import styled from 'styled-components';

export const AccommodationContainer = styled.div`
	/* border: 1px dashed teal; */
	margin-bottom: 30px;
	margin-top: 30px;
	@media (min-width: 1024px) {
		margin-top:40px;
		margin-bottom: 55px;
	}
`;

export const SubTitle = styled.h4`
	width: 100%;
	font-family: Freight Big;
	color: #212c1f;
	text-align: center;
	text-transform: none;
	font-size: 36px;
	padding-bottom: 15px;
	padding-top: 15px;
	margin-bottom: 20px;
	position: relative;

	@media (min-width: 768px) {
		font-size: 42px;
		margin-bottom: 30px;
		padding-top: 30px;

		padding-bottom: 30px;
	}

	@media (min-width: 1280px) {
		font-size: 48px;
		margin-bottom: 50px;
	}

	::before {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		height: 1px;
		width: 100%;
		background-color: black;
		opacity: 1;
	}

	::after {
		position: absolute;
		content: "";
		bottom: 0;
		left: 0;
		height: 1px;
		width: 100%;
		background-color: black;
		opacity: 1;
	}
`;

export const AccommodationTeaserWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;

	@media (min-width: 480px) {
		grid-template-columns: 1fr 1fr;

		grid-gap: 20px;
	}

	@media (min-width: 768px) {
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}
	@media (min-width: 1280px) {
		grid-gap: 50px;
	}

	.last-teaser {
		padding-bottom: 60px;
	}
`;

export const ItinerariesContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	/* border: 1px dashed red; */
	margin-top: 45px;
`;

export const ItinerariesTeaserWrapper = styled.div`
	display: grid;
	width: 100%;
	grid-gap: 10px;
	margin: 0 auto;

	@media (min-width: 600px) {
		grid-auto-flow: column;
		grid-auto-columns: minmax(10px, 363px);
		grid-gap: 20px;
	}
	@media (min-width: 768px) {
		width: auto;
		grid-gap: 30px;
	}

	@media (min-width: 1280px) {
		grid-gap: 55px;
	}
`;

export const StoriesTeaserWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	/* grid-gap: 10px; */
	position: relative;
	overflow: visible;

	@media (min-width: 480px) {

		grid-template-columns: 1fr 1fr;
	grid-column-gap: 18px;

	}

	@media (min-width: 768px) {
		grid-gap: 26px;
	}

	@media (min-width: 1280px) {
		grid-gap: 40px;
	}
	:after {
		content: "";
		position: absolute;
		width: 100%;
		background: black;
		bottom: 0;
		left: 0;
		height: 1px;
	}
`;

export const WidgetContainer = styled.div`

	/* border: 1px dashed green; */
	margin-bottom: 50px;
	margin-top: 50px;
	@media (min-width: 1024px) {
		margin-top:70px;
		margin-bottom: 75px;
	}
	> h3 {
		font-size: 27px;
		text-align: center;
		margin: 0 auto;
		line-height: 100%;
		margin-bottom: 20px;

		@media (min-width: 768px) {
			font-size: 32px;
			margin-bottom: 20px;
			max-width: 780px;
		}

		@media (min-width: 1024px) {
			font-size: 42px;
			margin-bottom: 30px;
		}
	}

	> p {
		font-size: 16px;
		line-height: 150%;
		margin: 0 auto;
		text-align: center;
		font-weight: 300;
		color: #545c52;
		padding: 0 30px;
		margin-bottom: 30px;

		@media (min-width: 768px) {
			font-size: 18px;
			max-width: 780px;
		}

		@media (min-width: 1024px) {
			padding: 0;
			font-size: 20px;
			margin-bottom: 60px;
		}
	}
`;

export const OtherHighlightsContainer = styled.div`
	/* border: 1px dashed orange; */

	margin-bottom: 30px;
	margin-top: 30px;
	@media (min-width: 1024px) {
		margin-top:40px;
		margin-bottom: 40px;
	}
	> h4 {
		font-family: Freight Big;
		text-transform: none;
		text-align: center;
		font-size: 30px;

		@media (min-width: 768px) {
			font-size: 34px;
			margin-bottom: 30px;
		}
		@media (min-width: 1024px) {
			font-size: 36px;
			margin-bottom: 50px;
		}
	}
`;

export const HighlightsTeaserWrapper = styled.div`
	display: grid;
	overflow-x: hidden;
	grid-template-columns: 1fr 1fr;
	grid-row-gap: 20px;
	grid-column-gap: 15px;

	@media (min-width: 768px) {
		grid-gap: 30px;

		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	@media (min-width: 1280px) {
		grid-gap: 55px;
	}
`;

export const EnterCompetitionContainer = styled.div`
	/* border: 1px dashed blue; */
	position:relative;
	display: grid;
	grid-template-columns: 1fr;
	margin-bottom: 30px;
	margin-top: 30px;
	@media (min-width: 1024px) {
		margin-top:40px;
		margin-bottom: 40px;
	}

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
`;

export const CompetitionImageWrapper = styled.div`
	margin-bottom: 20px;

	figure, img {
		width: 100%;
		height: 100%;
		margin: 0;
		display: block;
		object-fit: cover;
	}

	@media (min-width: 768px) {
		margin: 0;
	}

	.gatsby-image-wrapper {
		height: 100%;
	}
`;
export const CompetitionContent = styled.div`

p {
		@media (min-width: 1024px) {
			font-size: 18px;
			margin-bottom: 30px;
		}
	}

	ul {
		margin: 0;

		li {
			list-style-type: none;
			padding-left: 20px;
			font-size: 16px;
			position: relative;
			margin-bottom: 10px;

			::before {
				content: '';
				height: 6px;
				width: 6px;
				background: #a55331;
				display: block;
				border-radius: 50%;
				position: absolute;
				top: 8px;
				left: 0px;
			}

			@media (min-width: 1024px) {
				font-size: 18px;
				margin-bottom: 16px;

				::before {
					top: 11px;
				}
			}
		}
	}
`;

export const CompetitionTextWrapper = styled.div`
	@media (min-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 60px;
		padding-right: 40px;
	}

	@media (min-width: 1024px) {
		padding-top: 30px;
		padding-bottom: 20px;
		padding-left: 100px;
		padding-right: 60px;
	}
	@media (min-width: 1280px) {
		padding-top: 50px;
		padding-bottom: 40px;
		padding-left: 130px;
		padding-right: 100px;
	}
`;

export const CompetitionInfoWrapper = styled.div`
	position: relative;
	padding-bottom: 13px;
	margin-bottom: 20px;

	@media (min-width: 1024px) {
		margin-bottom: 30px;
	}

	h3 {
		font-size: 22px;
		position: relative;
		padding-bottom: 15px;
		margin-bottom: 20px;

		@media (min-width: 1024px) {
			font-size: 28px;
			margin-bottom: 26px;
		}
		::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: #b9bab4;
		}
	}



	::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background: #b9bab4;
	}
`;

export const Dates = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 19px;

	@media (min-width: 1024px) {
		margin-bottom: 35px;
	}

	svg {
		margin-right: 10px;
		width: 22px;
		stroke: #a55331;
	}
	p {
		margin: 0;
		color: #545c52;
		font-size: 14px;
	}
`;
