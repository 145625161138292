import React, { Component } from 'react'
import Slider from 'react-slick'

//components

//styles
import { Container, Header, SelectorContainer } from './style.js'
import { TeaserUnearth } from '../TeaserUnearth'

class StayPlay extends Component {
  constructor(props) {
    super(props)
    this.state = { active: 'stay' }

    this.changeSelection = this.changeSelection.bind(this)
  }

  changeSelection(value) {
    this.setState({ active: value })
  }

  render() {
    const settings = {
      slidesToShow: 3,
      unslick: true,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            unslick: false,
            slidesToScroll: 1,
            infinite: true,
            speed: 700,
            lazyLoad: true,
          },
        },
      ],
    }

    return (
      <Container opaque={this.state.opaque}>
        <Header>Where to</Header>
        <SelectorContainer active={this.state.active}>
          <button
            className="stay-btn"
            onClick={() => this.changeSelection('stay')}
          >
            <h4>Stay</h4>
          </button>
          <button
            className="play-btn"
            onClick={() => this.changeSelection('play')}
          >
            <h4>Play</h4>
          </button>
          <button
            className="eat-btn"
            onClick={() => this.changeSelection('eat')}
          >
            <h4>Eat</h4>
          </button>
          <button
            className="drink-btn"
            onClick={() => this.changeSelection('drink')}
          >
            <h4>Drink</h4>
          </button>
        </SelectorContainer>

        <Slider
          ref={c => (this.slider = c)}
          active={this.state.active}
          {...settings}
        >
          {(this.state.active === 'stay'
            ? this.props.dataStay
            : this.state.active === 'play'
            ? this.props.dataPlay
            : this.state.active === 'eat'
            ? this.props.dataEat
            : this.props.dataDrink
          ).map((teaser, index) => {
            return (
              <TeaserUnearth
                key={index}
                accomodation
                standard
                path={teaser.uri}
                title={teaser.title}
                excerpt={teaser.excerpt}
                fluid={{
                  ...teaser.image,
                  sizes: '350px',
                }}
              />
            )
          })}
        </Slider>
      </Container>
    )
  }
}

export default StayPlay
